exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-case-studies-gaintain-tsx": () => import("./../../../src/pages/case_studies/gaintain.tsx" /* webpackChunkName: "component---src-pages-case-studies-gaintain-tsx" */),
  "component---src-pages-case-studies-phronesis-tsx": () => import("./../../../src/pages/case_studies/phronesis.tsx" /* webpackChunkName: "component---src-pages-case-studies-phronesis-tsx" */),
  "component---src-pages-case-studies-querque-candles-tsx": () => import("./../../../src/pages/case_studies/querque_candles.tsx" /* webpackChunkName: "component---src-pages-case-studies-querque-candles-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */)
}

